import utils from '@/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  computed: {
    isUserTester() {
      return ''
    },

    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },

  methods: {

    generateRandomColors(numColors) {
      const colors = new Set() // Usar um Set para evitar duplicatas
      const saturation = 70 // Saturação constante (pode ajustar conforme preferido)
      const lightness = 50 // Luminosidade constante (pode ajustar conforme preferido)

      // Inicia em um tom verde (100) e se distribui uniformemente ao longo do espectro
      const startHue = 100 // Começar em um tom verde
      const hueStep = Math.floor(360 / numColors) // Aumentar o passo entre as cores

      while (colors.size < numColors) { // Enquanto o número de cores únicas for menor que numColors
        const randomOffset = Math.floor(Math.random() * (360 / hueStep)) // Gera um deslocamento aleatório
        const hue = (startHue + randomOffset * hueStep) % 360 // Calcula a cor HSL
        const randomColor = `hsl(${hue}, ${saturation}%, ${lightness}%)` // Formato HSL

        colors.add(randomColor) // Adiciona a nova cor ao Set (ignora se já existir)
      }

      return Array.from(colors) // Converte o Set de volta para um array
    },

    elapsedTime2(startTime, dataAtual) {
      const x = new Date(startTime)
      const now = dataAtual
      let timeDiff = now - x
      timeDiff /= 1000

      const seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      const hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      const days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      const years = timeDiff

      if (years > 0) {
        return this.$i18n.t('tempoAtras.ano', { count: years })
      } if (days > 0) {
        return this.$i18n.t('tempoAtras.dia', { count: days })
      } if (hours > 0) {
        return this.$i18n.t('tempoAtras.hora', { count: hours })
      } if (minutes > 0) {
        return this.$i18n.t('tempoAtras.minuto', { count: minutes })
      } if (seconds > 0) {
        return this.$i18n.t('tempoAtras.segundo', { count: seconds })
      }

      return this.$i18n.t('tempoAtras.agora')
    },

    sanitizeTitle(title) {
      let slug = ''
      // Change to lower case
      const titleLower = title.toLowerCase()
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
      // Letter "c"
      slug = slug.replace(/ç/gi, 'c')
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd')
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '')
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-')
      // remove &
      slug = slug.replace(/&/gi, 'e')
      // remove ?
      slug = slug.replace('?', '')
      return slug
    },

    scrollToElement(el) {
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },

    toFixed(price, limit) {
      return parseFloat(price).toFixed(limit)
    },

    getPercentDiff(num1, num2, toFixed) {
      if (toFixed) {
        return utils.percentDiff(num1, num2).toFixed(toFixed)
      }
      return utils.percentDiff(num1, num2).toFixed(2)
    },

    getPercentDiffSemSimbolo(num1, num2) {
      return utils.percentDiff(num1, num2).toFixed(2)
    },

    getDataDiff(dt1, dt2) {
      return utils.dateDiff(dt1, dt2)
    },

    getValorPercentual(valorTotal, percentual) {
      return utils.valorPercentual(valorTotal, percentual)
    },

    isDataRangeDias(dt1, dt2, dias) {
      if (typeof dt1 === 'string' || dt1 instanceof String) {
        const dt1Array = dt1.split('-')
        dt1 = new Date(dt1Array[0], dt1Array[1] - 1, dt1Array[2]) // eslint-disable-line no-param-reassign
      }

      if (typeof dt2 === 'string' || dt2 instanceof String) {
        const dt2Array = dt2.split('-')
        dt2 = new Date(dt2Array[0], dt2Array[1] - 1, dt2Array[2]) // eslint-disable-line no-param-reassign
      }

      const tmp = this.getDataDiff(dt1, dt2) <= (dias - 1)

      if (!tmp) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `A diferença entre as datas não pode ser superior a ${dias} dias`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      return tmp
    },

    getIcon(simbolo) {
      return `${process.env.VUE_APP_STATIC}icons/${simbolo.toLowerCase()}.png`
    },

    /* eslint-disable */
    elapsedTime(startTime) {
      return this.elapsedTime2(startTime, new Date())
    },

    elapsedTime2(startTime, dataAtual) {
      const x = new Date(startTime)
      const now = dataAtual
      let timeDiff = now - x
      timeDiff /= 1000

      const seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      const hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      const days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      const years = timeDiff

      if (years > 0) {
        return years + (years > 1 ? ' anos ' : ' ano ') + 'atrás'
      } else if (days > 0) {
        return days + (days > 1 ? ' dias ' : ' dia ') + 'atrás'
      } else if (hours > 0) {
        return hours + (hours > 1 ? ' horas ' : ' hora ') + 'atrás'
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? ' mins ' : ' min ') + 'atrás'
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? ' seg atrás' : 'agora')
      }

      return 'agora'
    },

    getDuracaoHoraMinuto(timestamp) {
      const horas = Math.floor(timestamp / 3600000); // 1 hora = 3600000 milissegundos
      const minutos = Math.floor((timestamp % 3600000) / 60000); // 1 minuto = 60000 milissegundos
      return `${horas}h ${minutos}min`;
    },

    formataValor(value, limit, convertUSD) {
      if (value) {
        if (convertUSD) {
          return value.toLocaleString('pt-BR', { style: 'currency', currency: 'USD' })
        }
        if (limit) {
          return parseFloat(value).toFixed(limit)
        }
        return value
      }
      return '-'
    },
  },
}
